import React from 'react';
import Section from './section';
import Airplane from '../../content/assets/plane.svg';
import { Parallax } from 'react-scroll-parallax';

const places = [
  'ARG',
  'ATA',
  'AUS',
  'AUT',
  'BEL',
  'BLZ',
  'BRA',
  'BTN',
  'CAN',
  'CHE',
  'CHL',
  'CHN',
  'COL',
  'CRI',
  'CUB',
  'CZE',
  'DEU',
  'ECU',
  'EGY',
  'ESP',
  'FRA',
  'GBR',
  'GRC',
  'GRL',
  'GTM',
  'GUM',
  'HKG',
  'HUN',
  'IND',
  'ISL',
  'ITA',
  'KHM',
  'LAO',
  'LKA',
  'MEX',
  'MYS',
  'NIC',
  'NLD',
  'NPL',
  'NZL',
  'PAN',
  'PER',
  'PRY',
  'QAT',
  'RSM',
  'RWA',
  'SGP',
  'SLV',
  'SVK',
  'SVN',
  'THA',
  'TUR',
  'TZA',
  'TWN',
  'UGA',
  'USA',
  'VAT',
  'VNM',
  'ZAF',
];

const DataMaps = typeof window !== `undefined` ? require('react-datamaps').default : () => <div/>; 

const Travel = () => {

	const countries = {};
	places.forEach(p => countries[p] = {fillKey: 'visitedFill'});


  return (
    <Section 
      anchor='adventures'
      title='Adventures'
      subtitle='I like to go places'
      index={2}
      >

      <Stats/>

      <div 
        style={{
          position: 'relative',
          overflow: 'hidden'
        }}
        >
        <Parallax x={[-10, 110]} y={[0, 600]} tagOuter="figure" styleOuter={{
          zIndex: '10',
              position: 'absolute',
              top: '5%',
              left: '5%',
              bottom: '5%',
              right: '5%',
        }}>
          <img 
            className='filter-accent' 
            src={Airplane} 
            style={{
              width: '10%',
              transform: 'rotate(10deg)',
            }} 
          />
        </Parallax> 
        <div 
          style={{marginTop:'-8%', zIndex: 0}}
          >
          <DataMaps
            responsive={true}
            geographyConfig={{
              hideAntarctica: false,
              popupOnHover: false,
              highlightOnHover: false,
            }}
            fills={{
              defaultFill: "#F2F2F2",
              visitedFill: '#363636', //"#65C9FF"
            }}
            data={countries}
          />
        </div>
        
      </div>

    </Section>
  );
}

export default Travel; 

const Stats = () => {
  return [
    <div className="adventure-stats level is-mobile">
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Planets</p>
          <p className="is-size-2">1</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Continents</p>
          <p className="is-size-2">7</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Countries</p>
          <p className="is-size-2">59</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">States</p>
          <p className="is-size-2">33</p>
        </div>
      </div>
    </div>,
    <div className="adventure-stats level is-mobile" style={{marginBottom: '3em'}}>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">National<br/> Parks</p>
          <p className="is-size-2">37</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Ancient <br/>Wonders</p>
          <p className="is-size-2">4</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Modern <br/>Wonders</p>
          <p className="is-size-2">6</p>
        </div>
      </div>
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Seven <br/>Summits</p>
          <p className="is-size-2">1</p>
        </div>
      </div> 
    </div>
  ];
};
